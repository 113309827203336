import { Row, Col } from 'reactstrap';
import style from './participant.module.scss';
import background from 'assets/images/background-logo.png' 
import timeoutBackground from 'assets/images/timeout.svg' 
import scoreImage from 'assets/images/score.svg';
import { useEffect, useState } from 'react';
import backgroundImage from 'assets/images/background.png'

const ParticipantWaiting = (props) => {
  const { IO, IOConnected, mode, title, onNavigate, refresh } = props;
  const [score, setScore] = useState(0);

  useEffect(()=>{
    return ()=>{
      setScore(0);
    }
  }, []);

  useEffect(()=>{
    if(IO && IOConnected) {
      if(mode === 'for-participants') {
        IO.on('navigate-to', (view) => {
          onNavigate(view);
        });
      }
      IO.emit('user-total-score');
      IO.on('user-total-score', (result) => {
        setScore(result);
      });
    }
  }, [IO, IOConnected, mode, onNavigate]);

  return (
    <>
      <Row className={style.waiting}>
        <Col style={{
            position:'relative',
            backgroundImage:`url(${backgroundImage})`,
          }}>
          {
            title &&
            <Row className="title">
              <Row>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <Row>
                <Col className="panel">
                  <span>{title}</span>
                </Col>
              </Row>
            </Row>
          }
          {(mode === 'for-next-question' /*|| mode === 'for-after-timeout'*/) &&
            <Row className="score">
              <Row><span style={{
                  backgroundImage:`url(${scoreImage})`
                }}></span></Row>
              <Row><i className="score-info">{score}</i></Row>
            </Row>
          }
          <Row className="image">
            <div
              style={{
                backgroundImage: `url(${
                  mode === 'for-after-timeout' ? timeoutBackground : background})`
              }}
            >
            </div>
          </Row>
          {
            mode === 'for-participants' &&
            <Row className="message">
              <Col>En attente des autres participants</Col>
            </Row>
          }
          {
            mode === 'for-patient-case' &&
            <Row className="message">
              <Col>Le cas patient va commencer</Col>
            </Row>
          }
          {
            mode === 'for-question' &&
            <Row className="message">
              <Col>La question va bientôt démarrer ! </Col>
            </Row>
          }
          {
            mode === 'for-next-question' &&
            <Row className="message">
              <Col>Vos réponses ont été enregistrées ! </Col>
              <Col style={{
                  fontFamily:'Roboto-Regular'
                }}>
                Découvrez l'explication des experts
              </Col>
            </Row>
          }
          { mode === 'for-after-timeout' &&
            <Row className="message">
              <Col style={{
                flexDirection:'column',
                display: 'flex',
                height: '100%',
              }}>
                <Row>
                  Vous n'avez pas soumis de réponse !
                </Row>
                <Row style={{
                  fontFamily:'Roboto-Regular'
                }}>
                  Découvrez l'explication des experts
                </Row>
              </Col>
            </Row>
          }
        </Col>
      </Row>
    </>
  );
}

export default ParticipantWaiting;
