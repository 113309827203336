import { useRef, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import style from './admin.module.scss';
import background from 'assets/images/background-logo.png' 
import logoLeft from 'assets/images/logo_jn.png'
import logoRight from 'assets/images/logo_mb.png'
import backgroundImage from 'assets/images/background.png'

const AdminWelcome = ({IO, IOConnected, onNavigate, refresh}) => {
  const start = useCallback(() => {
    if(IO && IOConnected) {
      // IO.emit('navigate-to', 'participant-cities');
      IO.emit('admin-ready-to-start');
      onNavigate('admin-participants');
    }
  }, [IO, IOConnected, onNavigate]);
  
  const ref = useRef();
  return (
    <Row style={{
        position:'relative',
        // backgroundImage:`url(${backgroundImage})`,
      }}
      className={style.welcome}>
      {
      /*
        <Row style={{
          position:'absolute',
          top: 0,
          left: 0,
          display: 'none',
        }}>
        <Col>
          <input type="text" ref={ref}/>
          <button type="button" onClick={() => {
            if(IO && IOConnected && ref?.current) {
              IO.emit(`navigate-to`, ref.current.value); 
            }
          }}>validate</button>
        </Col>
      </Row>
      */
      }
      <Col className="body" style={{
        position:'relative',
        backgroundImage:`url(${backgroundImage})`,
      }}>
        <Row className="image">
          <div
            style={{
              backgroundImage: `url(${background})`
            }}
          >
          </div>
        </Row>
        <div className="bottom">
          <Row>
            <Button onClick={start}>Commencer</Button>
          </Row>
          <Row />
          {/*<Row>
            <Col className="col-8">
              <div style={{backgroundImage:`url(${logoRight})`}} className="logo" />
            </Col>
            <Col className="col-4">
              <div style={{backgroundImage:`url(${logoLeft})`}} className="logo" />
            </Col>
          </Row>
        */}
        </div>
      </Col>
    </Row>
  );
}

export default AdminWelcome;
