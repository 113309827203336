import { useState, useCallback, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import AdminEnding from './AdminEnding';
import AdminParticipants from './AdminParticipants';
import AdminPatientCases from './AdminPatientCases';
import AdminQuestionDetails from './AdminQuestionDetails';
import AdminQuestions from './AdminQuestions';
import AdminRanking from './AdminRanking';
import AdminStatsDetails from './AdminStatsDetails';
import AdminStats from './AdminStats';
import AdminWelcome from './AdminWelcome';
import Stream from '../global/Stream';
import style from './admin.module.scss';

const AVAILABLE_VIEWS  = [
  'admin-welcome',
  'admin-participants',
  'admin-patient-case',
  'admin-ending',
  'admin-questions',
  'admin-question-details',
  'admin-stats',
  'admin-stats-details',
  'admin-ranking',
];

const AdminView = (props) => {
  /*const RenderStream = ({IO, IOConnected, role}) => {
    return <Stream
        IO={IO}
        IOConnected={IOConnected}
        role={role}
      />
  }*/

  const { IO, IOConnected, user, extra } = props;
  const [currentView, setCurrentView] = useState('admin-welcome');
  const [currentViewOptions, setCurrentViewOptions] = useState({});
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [cleaning, setCleaning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const handleGetBackTo = (data) => {
      setCurrentQuestions(data?.currentQuestions ? data.currentQuestions : []);
      setCurrentViewOptions(data.options);
      if (AVAILABLE_VIEWS.indexOf(data.view) !== -1) {
        setCurrentView(data.view);
      } else {
        setCurrentView('admin-welcome');
      }
    };

    const handleRefresh = () => {
      setRefresh(refresh + 1);
    };

    const handleResetDataBase = () => {
      setCleaning(false);
    };

    const handleGetBackToWelcome = () => {
      setCurrentView('admin-welcome');
    };

    if (IO && IOConnected) {
      IO.on('get-back-to', handleGetBackTo);
      IO.on('refresh', handleRefresh);
      IO.on('reset-data-base', handleResetDataBase);
      IO.on('get-back-to-welcome', handleGetBackToWelcome);
    }

    return () => {
      if (IO) {
        IO.off('get-back-to', handleGetBackTo);
        IO.off('refresh', handleRefresh);
        IO.off('reset-data-base', handleResetDataBase);
        IO.off('get-back-to-welcome', handleGetBackToWelcome);
      }
    };
  }, [IO, IOConnected, refresh]);

  useEffect(() => {
    return ()=>{
      setCurrentView('admin-welcome');
      setCurrentViewOptions({});
      setCurrentQuestions([]);
      setCleaning(false);
      setLoading(false);
      setRefresh(0);
    }
  }, []);

  const onNavigate = useCallback((view, options) => {
    if (options) {
      setCurrentViewOptions(options);
    } else {
      setCurrentViewOptions({});
    }
    setCurrentView(view);
    IO.emit('save-current-view', {view, options, currentQuestions});
  }, [IO, currentQuestions]);

  const onSetQuestions = useCallback((questions)=>{
    setCurrentQuestions(questions);
  }, []);

  const RenderRightSide = useCallback(() => {
    return <>
    {
      AVAILABLE_VIEWS.indexOf(currentView) === -1 ? <>
        <div>unvailable view</div>
      </> :
      <>
        { loading && <>
            <div>...</div>
          </>
        }
        { !loading && <>
          {currentView === 'admin-welcome' &&
            <AdminWelcome
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-participants' &&
            <AdminParticipants
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-patient-case' &&
            <AdminPatientCases
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-ending' &&
            <AdminEnding
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-questions' &&
            <AdminQuestions
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
              onSetQuestions={onSetQuestions}
            />
          }
          {currentView === 'admin-question-details' &&
            <AdminQuestionDetails
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
              currentQuestions={currentQuestions}
            />
          }
          {currentView === 'admin-stats' &&
            <AdminStats
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-stats-details' &&
            <AdminStatsDetails
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
          {currentView === 'admin-ranking' &&
            <AdminRanking
              IO={IO}
              IOConnected={IOConnected}
              onNavigate={onNavigate}
              options={currentViewOptions}
              refresh={refresh}
            />
          }
        </>
        }
      </>
    }
    </>
  }, [IO, IOConnected, currentQuestions, currentView,
    currentViewOptions, loading, onNavigate,
    onSetQuestions, refresh]);

  return (
    <>
      {/*
      <div style={{
        position:'absolute',
        bottom: '10px',
        left: '10px',
      }}>
        <Button onClick={() => {
          if (IO && IOConnected) {
            IO.emit('reset-session');
            onNavigate('admin-welcome');
          }
        }}>Reset</Button>
      </div>
      */}
      <Row className={style.view} style={{width: '100%'}}>
        {/*<Col>
          <RenderStream IO={IO} IOConnected={IOConnected} role={user?.role}/>
        </Col>
        */}
        <Col style={{
          height: '100vh',
          position: 'relative',
          padding:'10px',
        }}>
          {extra === 'special' &&
            <Button
              style={{
                position:'absolute',
                top: '0px',
                right: '0px',
                width: '100px',
                height: '100px',
                background: cleaning ? 'grey' : 'red',
                zIndex: '100',
              }}
              disabled={cleaning}
              onClick={()=>{
                IO.emit('reset-data-base');
                setCleaning(true);
              }}>{cleaning ? 'Cleaning...' : 'Clean data base'}</Button>
          }
          {RenderRightSide()}
        </Col>
      </Row>
    </>
  );
}

export default AdminView;
