import React, {forwardRef} from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = forwardRef((props, videoRef) => {
    const playerRef = React.useRef(null);
    const {options, onReady} = props;
    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) {
                return;
            }
            const player = playerRef.current = videojs(
                videoElement, options,
                () => {
                    onReady && onReady(player);
                }
            );
        } else {
            playerRef.current.autoplay(options.autoplay);
            playerRef.current.src(options.sources);
        }
    }, [onReady, options, videoRef]);

    React.useEffect(() => {
        const player = playerRef.current;
        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div
            data-vjs-player
            style={{
                width: '100%',
                height: '100%',
            }}
        >
        <video
            style={{
                width: '100%',
                height: '100%',
            }}
            ref={videoRef}
            className='video-js vjs-big-play-centered'
        />
        </div>
    );
});

export default VideoJS;