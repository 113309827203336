import { useEffect, useCallback, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { getBadges } from '../global/Util';
import style from './participant.module.scss' ;
import backgroundImage from 'assets/images/background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';

const ParticipantCity = (props) => {
  const { IO, IOConnected, onNavigate, onRegister, options, refresh } = props;
  const [isReady, setIsReady] = useState(false);

  //options.city.name
  const renderCity = useCallback(() => {
    const badge = getBadges(options.city.id);
    return(
      <>
        {
          <span className="badge" style={{
            backgroundImage:`url(${badge.data256})`,
          }}/>
        }
      </>
    );
  }, [options.city]);

  useEffect(() => {
    const handleReadyToConfirmCity = (result) => {
      setIsReady(result);
    };

    const handleReadyToConfirmCityByAdmin = () => {
      setIsReady(true);
    };

    if (IO && IOConnected) {
      IO.emit('is-ready-to-confirm-city');
      IO.on('is-ready-to-confirm-city', handleReadyToConfirmCity);
      IO.on('is-ready-to-confirm-city-forced-by-admin', handleReadyToConfirmCityByAdmin);
    }

    return () => {
      if (IO) {
        IO.off('is-ready-to-confirm-city', handleReadyToConfirmCity);
        IO.off('is-ready-to-confirm-city-forced-by-admin', handleReadyToConfirmCityByAdmin);
      }
    };
  }, [IO, IOConnected, refresh /*important*/]);


  return (
  <>
    <Row className={style.city}>
      <Col style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
        <Row className="header">
          <Col className="title" style={{
            cursor:'initial',
          }}>
            <div
              role="button"
              tabIndex={0}
              className="back"
              onClick={()=>{
              IO.emit('unselect-city', options.city.id);
              onNavigate('participant-cities');
            }}>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
            </div>
            <h1>Selectionnez votre ville</h1>
          </Col>
        </Row>
        <Row className="body">
          { renderCity() }
        </Row>
        <Row className="footer">
          <Button disabled={!isReady} onClick={()=>{
            onRegister();
            onNavigate('participant-waiting-for-participants');
            if (IO && IOConnected) {
              IO.emit('register-city', options.city.id);
            }
          }}>
            { !isReady && <span>Merci de patienter</span> }
            { isReady && <span>Confirmer</span> }
          </Button>
        </Row>
      </Col>
    </Row>
  </>
  );
}

export default ParticipantCity;
