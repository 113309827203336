import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import boosterImageNotAct from 'assets/images/booster.svg';
import boosterImageGold from 'assets/images/booster_gold.svg'
import timerImage from 'assets/images/timer.png';
import infoImage from 'assets/images/icon_info.png';
import scoreImage from 'assets/images/score.svg';
import starImage from 'assets/images/gamemode/pistolero.svg';
import classicoImage from 'assets/images/gamemode/classico.svg'
import riskImage from 'assets/images/gamemode/priserisque.svg'
import style from './participant.module.scss' ;

const ParticipantQuizz = (props) => {
  const { IO, IOConnected, refresh, onNavigate, options } = props;
  const [selected, setSelected] = useState([]);
  const [points, setPoints] = useState([]);
  const [question, setQuestion] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(99999);
  const [risky, setRisky] = useState(false);
  const [booster, setBooster] = useState(false);
  const [showModalRisk, setShowModalRisk] = useState(true)
  const [showModalBoost, setShowModalBoost] = useState(false)
  const [boosterImage, setBoosterImage] = useState(boosterImageNotAct)

  useEffect(() => {
    if (options?.showModalRisk === false) {
      setShowModalRisk(options?.showModalRisk);
      setRisky(options?.risky);
    }
  }, [options?.showModalRisk, showModalRisk, options?.risky, risky])

  const validateTakeRisk = useCallback(() => {
    setRisky(true);
    setShowModalRisk(false);
  }, []);

  const activateBooster = useCallback(() => {
    if (booster) {
      return;
    }
    setBoosterImage(boosterImageGold);
    setBooster(true);
    setShowModalBoost(true);
  }, [booster])

  const confirmBooster = useCallback(() => {
    setShowModalBoost(false);
  }, [])

  const cancelBooster = useCallback(() => {
    setBooster(false);
    setShowModalBoost(false);
    setBoosterImage(boosterImageNotAct);
  }, [])

  const validate = useCallback(() => {
    if (IO && IOConnected) {
      const multiple = question.hasUniqueAnswer ? 0 :
        question.answers.reduce((p, c) => (p + (c.points ? 1 : 0)), 0);
      const risky_multiple = risky ? 1.5 : 1
      const booster_multiple = booster ? 2 : 1
      const sum = points.reduce((a, b) => (a + b), 0);
      const p = 
        (multiple && (multiple * 10 > sum)) ? 0 :
        (points.indexOf(0) === -1 ? sum / points.length : 0);
      const final_result = risky_multiple * booster_multiple * p
      IO.on('save-answer-done', () => {
        onNavigate('participant-waiting-for-next-question');
      });
      IO.emit('save-answer', {ids: selected, points: final_result, qid:question.id});
    }
  }, [IO, IOConnected, onNavigate, selected, points, question, risky, booster]);

  const getTime = useCallback((seconds) => {
    const min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;
    if (sec < 0) {
      sec = 0;
    }
    return `${`0${min}`.slice(-2)}:${`0${sec}`.slice(-2)}`;
  }, []);

  const isRisky = (value) => {
    if (risky) {
      return true;
    }
    const { hidden } = value
    if (hidden) {
      return false
    }
    return true
  }

  useEffect(()=> {
    return ()=>{
      setSelected([]);
      setPoints([]);
      setQuestion(null);
      setShowModal(false);
      setFetched(false);
      setScore(0);
      setTimer(99999);
    }
  }, []);

  useEffect(() => {
    if (timer === 99999 && question?.time) { 
      if (options.time !== undefined) {
        setTimer(options.time);
      } else {
        setTimer(question.time);
      }
    }
  }, [options?.time, question?.time, timer]);

  useEffect(() => {
    let timerId;
    if (timer >= 0 && question?.time) {
      timerId = setTimeout(() => {
        const time = timer - 1;
        setTimer(time >= 0 ? time : 0);
        if (time <= 0) {
          onNavigate('participant-waiting-for-after-timeout');
        }
        clearTimeout(timerId);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [onNavigate, question?.time, timer]);

  const prevRefresh = usePrevious(refresh);

  useEffect(() => {
    if (IO && IOConnected) {
      if(!fetched || refresh !== prevRefresh) {
        IO.emit('current-question');
        IO.on('current-question', (result) => {
          setQuestion(result);
          console.log('Current question fetched');
        });
        IO.emit('user-total-score');
        IO.on('user-total-score', (result) => {
          setScore(result);
        });
        setFetched(true);
      }
    }
    return () => {
      setQuestion(null);
    }
  }, [IO, IOConnected, fetched, refresh, prevRefresh /*important*/]);

  return (
    <>
      {
        showModal && <>
          <Modal
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      {
        Boolean(question?.with_hidden) && showModalRisk && <>
          <Modal
            title="Souhaitez-vous prendre un risque ?"
            onValidate={validateTakeRisk}
            onCancel={()=>{
              setShowModalRisk(false);
            }}
          />
        </>
      }
      {
        showModalBoost && <>
          <Modal
            title="Souhaitez-vous appliquer un boost ?"
            onValidate={confirmBooster}
            onCancel={()=>{
              cancelBooster();
            }}
          />
        </>
      }
      <Row className={style.quizz}>
        <Col style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="header">
            <Col>
              <Row>
                <img style={{
                  cursor: 'pointer',
                }} onClick={()=>{
                  if (question?.gameMode === 'pistolero') {
                    onNavigate('participant-rules-pistolero', {time: timer})
                  } else if (question?.gameMode === 'prise2risque') {
                    options.risky = risky
                    onNavigate('participant-rules-prise2risque', {time: timer, risky: risky, showModalRisk: showModalRisk})
                  } else {
                    onNavigate('participant-rules', {time: timer});
                  }
                }}
                alt="" src={infoImage} />
              </Row>
              <Row>
                {console.log(question?.with_booster)}
                {
                  Boolean(question?.with_booster) && <div onClick={activateBooster} className="pistolero" style={{
                    backgroundImage: `url(${boosterImage})`
                  }}></div>
                }
              </Row>
            </Col>
            <Col>
            <span style={{
              backgroundImage:`url(${timerImage})`
            }}>{getTime(timer)}</span></Col>
            <Col className="score">
              <Row><span style={{
                backgroundImage:`url(${scoreImage})`
              }}></span></Row>
              <Row><i className="score-info">{score}</i></Row>
            </Col>
          </Row>
          <Row className="body">
            <Col>
            {question ?
              <>
                <Row>
                  <Col className="question">{question.name}</Col>
                </Row>
                {
                  question.answers.filter(isRisky).map((answer, index) =>
                    <Row className="answer" key={index}>
                      <Col style={{
                        position: 'relative',
                      }}>
                      <div style={{
                        fontSize:'12px',
                        position: 'absolute',
                        left: '4px',
                        top: '4px',
                        display: `${answer.chosen ? 'block': 'none'}`,
                      }}>Précédente réponse</div>
                      <Button
                        className={`${selected.indexOf(answer.id) !== -1  ? 'selected' : ''}`}
                        outline
                        color="secondary"
                        onClick={()=>{
                          if (question.hasUniqueAnswer) {
                            setSelected([answer.id]);
                            setPoints([answer.points]);
                          } else {
                            const i = selected.indexOf(answer.id);
                            if (i === -1) {
                              setSelected([...selected, answer.id]);
                              setPoints([...points, answer.points]);
                            } else {
                              let f = 0; setSelected(selected.filter(
                                (v, i)=> (v !== answer.id) || f++));
                              f = 0; setPoints(points.filter(
                                (v, i)=> (v !== answer.points) || f++));
                            }
                          }
                        }}
                  >{answer.name}</Button></Col>
                    </Row>
                  )
                }
              </>
            : ''}
            </Col>
          </Row>
          <Row className="footer">
            <Col> 
              <Button
                disabled={selected.length === 0}
                onClick={()=>{
                setShowModal(true);
              }}>Valider</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default ParticipantQuizz;
