import { useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import style from './modal.module.scss' ;

const Modal = (props) => {
  const { title, onValidate, onCancel } = props;
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    return () => {
      setDisabled(false);
    }
  }, []);

  return (
  <>
    <Row className={style.modal}>
      <Col className="container">
        <Row />
        <Row className="body">
          <Row className="title">
            {<span>{(title === undefined) ? "Souhaitez-vous confirmer votre choix ?" : `${title}`}</span>}
          </Row>
          <Row>
            <Col>
              <Button disabled={disabled} onClick={(e) => {
                setDisabled(true);
                onValidate(e);
              }}>Oui</Button>
            </Col>
            <Col>
              <Button disabled={disabled} onClick={(e) => {
                setDisabled(true);
                onCancel(e);
              }}>Non</Button>
            </Col>
          </Row>
        </Row>
        <Row/>
      </Col>
    </Row>
  </>
  );
}

export default Modal;
