import { useEffect, useState, useCallback } from 'react'
import { Row, Col, Button } from 'reactstrap'
import { getBadges } from '../global/Util'
import backgroundImage from 'assets/images/background.png'
import style from './admin.module.scss' 

const AdminParticipants = (props) => {
  const { IO, IOConnected, onNavigate, refresh } = props;
  const [ cities, setCities ] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);


  const start = useCallback(() => {
    if(IO && IOConnected) {
      IO.emit('navigate-to', 'participant-waiting-for-patient-case');
      IO.emit('session-has-sarted');
      onNavigate('admin-patient-case');
    }
  }, [IO, IOConnected, onNavigate]);

  const renderCols = useCallback((index) => {
    const citiesList = [];
    Array(3).fill(0).forEach((_, cell) => {
      const cityIndex = (index * 3) + cell;
      const city = cities[cityIndex];
      if (city) {
        const badge = getBadges(city.id);
        citiesList.push(
          <Col className="city" key={`${index} ${cell}`}>
            <i className="badge" style={{
              opacity: selectedCities.includes(city.id) ? 0.5 : 1,
              backgroundImage: badge ? `url(${badge.data})` : 'unset',
            }}/>
          </Col>
        );
      } else {
        citiesList.push(<Col key={`empty-${cityIndex}`}/>);
      }
    });
    return (
      <>
        {citiesList}
      </>
    );
  }, [cities, selectedCities, onNavigate]);

  const renderRows = useCallback(() => {
    const rows = [];
    Array(Math.ceil(cities.length / 3)).fill(0).forEach((_, index) =>
      rows.push(
        <Row key={index}>
          {
            renderCols(index)
          }
        </Row>
      )
    )
    return rows;
  }, [cities?.length, renderCols]);

  useEffect(() => {
    return () => {
      setCities(null);
    };
  }, []);

  useEffect(() => {
    if (IO && IOConnected) {
      IO.emit('cities');
      IO.on('cities', (result) => {
        setCities(result);
      });

      IO.on('selectedCities', (selectedCities) => {
        setSelectedCities(JSON.parse(selectedCities))
      })
    }
  }, [IO, IOConnected, refresh /*importent*/]);

  return (
  <>
    <Row className={style.participants}>
      <Col style={{
          backgroundImage:`url(${backgroundImage})`,
        }}>
        <Row className="header">
          <h1>Participants connectés</h1>
        </Row>
        <Row className="body">
          <Col>
            {cities ?
              renderRows()
            : ''}
          </Col>
        </Row>
        <Row className="footer">
          <Col>
            <Row>
              <Col>
                <Button onClick={start}>Démarrer</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>

  </>
  );
}

export default AdminParticipants;
