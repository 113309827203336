import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from 'assets/images/background.png';
import starImage from 'assets/images/star.png';
import style from './admin.module.scss';

const AdminQuestions = ({
    IO, IOConnected, onNavigate,
    options, refresh, onSetQuestions
  }) => {
  const [questions, setQuestions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [showResetModal, setShowResetModal] = useState(false);
  // const [nextIndex, setNextIndex] = useState(0);

  const validateReset = useCallback(() => {
    if (IO && IOConnected) {
      IO.emit('reset-session');
      // onNavigate('admin-welcome');
    }
  }, [IO, IOConnected]);

  const validate = useCallback(() => {
    if (IO && IOConnected) {
      IO.emit('navigate-with-params-to', {
        navigation: 'participant-quizz',
        params: {id: selected}
      });
      onNavigate('admin-question-details', {
        question: questions.find(
          (question) => question.id === selected
        ),
        case: options.selected,
        questionNumber,
        patientCaseNumber: options.patientCaseNumber
      });
    }
  }, [IO, IOConnected, onNavigate, questionNumber,
      options?.selected, questions, selected, options?.patientCaseNumber]);

  useEffect(() => {
    return () => {
      setQuestions([]);
      setSelected(null);
      setShowModal(false);
      setFetched(false);
      setQuestionNumber(0);
      setShowResetModal(false);
    }
  }, []);

  const prevRefresh = usePrevious(refresh);

  useEffect(() => {
    if (IO && IOConnected && options.selected) {
      if(!fetched || +refresh !== +prevRefresh) {
        IO.emit('questions', options.selected);
        IO.on('questions', (questions) => {
          setQuestions(questions);
          onSetQuestions(questions);
          console.log('Questions fetched');
        });
        setFetched(true);
      }
    }
  }, [IO, IOConnected, options, options?.selected,
      onSetQuestions, fetched, refresh, prevRefresh /*important*/]);

  return (
    <>
      {
        showResetModal && <>
          <Modal
            onValidate={validateReset}
            onCancel={()=>{
              setShowResetModal(false);
            }}
          />
        </>
      }
      {
        showModal && <>
          <Modal
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      <Row className={style.questions}>
        <Col style={{
              backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="header">
            <Row>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-stats');
                }}><span>Statistiques</span></Button>
              </Col>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-ranking', {
                    origin: 'admin-questions',
                    ...options,
                  });
                }}><span>Classement</span></Button>
              </Col>
              <Col>
                <Button onClick={() => {
                    setShowResetModal(true);
                  }}><span>Reset</span></Button>
              </Col>
            </Row>
            {/*<Row />*/}
          </Row>
          <Row className="previous">
            <Col>
              <Button onClick={() => {
                onNavigate('admin-patient-case', {selected: options.selected});
              }}>
                <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
              </Button>
            </Col>
            <Col>Cas patient {options.patientCaseNumber}</Col>
          </Row>
          <Row className="body">
            <Col>
              {questions?.length ? questions.map((question, index) =>
                <Row className="selectable" key={index}>
                  <Col>
                    { question?.gameMode === 'pistolero' &&
                      <div className="pistolero-badge" style={{
                        backgroundImage: `url(${starImage})`
                      }} />
                    }
                    <Button
                      className={`${question.id === selected ? 'selected' : ''}`}
                      // outline={!question.done}
                      style={{
                        opacity:`${question.done ? 0.5 : 1}`,
                      }}
                      disabled={question.done}
                      color="secondary"
                      onClick={()=>{
                        setSelected(question.id);
                        setQuestionNumber(index + 1);
                        // setNextIndex(index + 1);
                      }}
                    >{question.name}</Button>
                  </Col>
                </Row>
              ): ''}
            </Col>
          </Row>
          <Row className="footer">
            <Col>
            <Button
              disabled={selected === null}
                onClick={()=>{
                setShowModal(true);
              }}>Valider</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default AdminQuestions;
