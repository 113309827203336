import { useCallback, useEffect, useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import style from '../admin/admin.module.scss';
import logoLeft from 'assets/images/logo_jn.png'
import logoRight from 'assets/images/logo_mb.png'
import background from 'assets/images/background-logo.png'
import backgroundImage from 'assets/images/background.png'

const ParticipantWelcome = ({IO, IOConnected, onNavigate, refresh}) => {
  const [readyToStart, setReadyToStart] = useState(false);

  useEffect(()=>{
    return ()=>{
      setReadyToStart(false);
    }
  }, []);

  const start = useCallback(() => {
    if (IO && IOConnected) {
      onNavigate('participant-cities');
    }
  }, [IO, IOConnected, onNavigate]);

  useEffect(() => {
    if (IO && IOConnected) {
      const handleSessionExist = (ready) => {
        setReadyToStart(ready);
      };

      IO.emit('is-session-exist');
      IO.on('is-session-exist', handleSessionExist);

      return () => {
        IO.off('is-session-exist', handleSessionExist); // Nettoyer l'écoute du socket lorsque le composant est démonté
      };
    }
  }, [IO, IOConnected, refresh]); // important

  return (
    <Row style={{
        position:'relative',
      }}
      className={style.welcome}>
      <Col className="body" style={{
        position:'relative',
        backgroundImage:`url(${backgroundImage})`,
      }}>
        <Row className="image">
          <div
            style={{
              backgroundImage: `url(${background})`
            }}
          >
          </div>
        </Row>
        <div className="bottom">
          <Row>
            <Button
              disabled={!readyToStart}
              onClick={start}>Commencer</Button>
          </Row>
          <Row />
          {/*<Row>
            <Col className="col-8">
              <div style={{backgroundImage:`url(${logoRight})`}} className="logo" />
            </Col>
            <Col className="col-4">
              <div style={{backgroundImage:`url(${logoLeft})`}} className="logo" />
            </Col>
          </Row>
        */}
        </div>
      </Col>
    </Row>
  );
}

export default ParticipantWelcome;
