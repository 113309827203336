import { useEffect, useRef } from 'react';
// Could be moved to data base
import agen from '../assets/images/badges/badge_ville_agen_128.png';
import agen_256 from '../assets/images/badges/badge_ville_agen_256.png';
import brest from '../assets/images/badges/badge_ville_brest_128.png';
import brest_256 from '../assets/images/badges/badge_ville_brest_256.png';
import la_rochelle from '../assets/images/badges/badge_ville_la_rochelle_128.png';
import la_rochelle_256 from '../assets/images/badges/badge_ville_la_rochelle_256.png';
import nantes from '../assets/images/badges/badge_ville_nantes_128.png';
import nantes_256 from '../assets/images/badges/badge_ville_nantes_256.png';
import paris1 from '../assets/images/badges/badge_ville_paris1_128.png';
import paris1_256 from '../assets/images/badges/badge_ville_paris1_256.png';
import paris2 from '../assets/images/badges/badge_ville_paris2_128.png';
import paris2_256 from '../assets/images/badges/badge_ville_paris2_256.png';
import grenoble from '../assets/images/badges/badge_ville_grenoble_128.png';
import grenoble_256 from '../assets/images/badges/badge_ville_grenoble_256.png';
import marseille from '../assets/images/badges/badge_ville_marseille_128.png';
import marseille_256 from '../assets/images/badges/badge_ville_marseille_256.png';


const BADGES = [
  { 
    id: 1,
    data: agen,
    data256: agen_256
  },
  {
    id: 2,
    data: brest,
    data256: brest_256
  },
  {
    id: 3,
    data: grenoble,
    data256: grenoble_256
  },
  {
    id: 4,
    data: la_rochelle,
    data256: la_rochelle_256
  },
  {
    id: 5,
    data: marseille,
    data256: marseille_256
  },
  {
    id: 6,
    data: nantes,
    data256: nantes_256
  },
  {
    id: 7,
    data: paris1,
    data256: paris1_256
  },
  {
    id: 8,
    data: paris2,
    data256: paris2_256
  }
];

export const getBadges = (id) => {
  return BADGES.find((element) => {
    return id === element.id;
  });
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export default usePrevious;
