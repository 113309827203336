import { useEffect, useState, useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import style from './admin.module.scss';

const AdminStats = ({IO, IOConnected, onNavigate, options, refresh}) => {
  const [questions, setQuestions] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [currentCase, setCurrentCase] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [patientCases] = useState([
    {name:'Cas 1', id: '1'},
    {name:'Cas 2', id: '2'},
  ]);
  const validate = useCallback(() => {
    if (IO && IOConnected) {
      onNavigate('admin-stats-details', {
        question: questions.find(
          (question) => question.id === selected
        ),
        case: currentCase,
        questionNumber,
      });
    }
  }, [IO, IOConnected, onNavigate, currentCase,
      questions, selected, questionNumber]);

  useEffect(() => {
    return () => {
      setQuestions([]);
      setSelected(null);
      setShowModal(false);
      setFetched(false);
    }
  }, []);

  const previous = usePrevious({refresh, currentCase});
 
  useEffect(() => {
    if (IO && IOConnected) {
      if(!fetched ||
          +refresh !== +previous.refresh ||
          +currentCase !== +previous.currentCase
        ) {
        if (!currentCase) {
          if(!options.selected) {
            IO.emit('questions', 1);
          } else {
            setCurrentCase(options.selected);
            IO.emit('questions', options.selected);
          }
        } else {
          IO.emit('questions', currentCase);
        }
        IO.on('questions', (questions) => {
          setQuestions(questions);
          console.log('Questions fetched');
        });
        setFetched(true);
      }
    }
  }, [IO, IOConnected, currentCase, previous,
      fetched, options.selected, refresh /*important*/]);

  return (
    <>
      {
        showModal && <>
          <Modal
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      <Row className={style.questions}>
        <Col style={{
            backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="stats-top">
            <div><button type="button" onClick={()=>{
                onNavigate('admin-patient-case');
              }}>+</button></div>
            <Row><span>Statistique</span></Row>
            <Row>
              {patientCases &&
                patientCases.map((value, index) => {
                  return (
                    <Col className="nav" key={`stats-top-${index}`}>
                      <Button
                        className={
                          `${value.id === currentCase ||
                              (currentCase === null && +value.id === 1) ?
                              'selected' : ''}`
                        }
                        onClick={()=>{
                          setCurrentCase(value.id);
                          setSelected(null);
                        }}>{value.name}</Button>
                    </Col>
                  )
                })
              }
            </Row>
            {/*<Row style={{
              maxHeight: '10px'
            }}/>*/}
          </Row>
          <Row className="body">
            <Col>
              <div style={{height:'10px', width: '100%'}} />
              {questions?.length ? questions.map((question, index) =>
                <Row className="selectable" key={index}>
                  <Col>
                    <Button
                      className={`${question.id === selected ? 'selected' : ''}`}
                      outline={!question.done}
                      color="secondary"
                      onClick={()=>{
                        setSelected(question.id);
                        setQuestionNumber(index + 1);
                      }}
                    >{question.name}</Button>
                  </Col>
                </Row>
              ): ''}
            </Col>
          </Row>
          <Row className="footer">
            <Col>
            <Button
              disabled={selected === null}
                onClick={()=>{
                setShowModal(true);
              }}>Valider</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default AdminStats;
