import {useEffect, useState, useCallback, useRef} from 'react';
import { Row, Col, Button } from 'reactstrap';
import Modal from '../global/Modal';
import usePrevious from '../global/Util';
import backgroundImage from 'assets/images/background.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import starImage from 'assets/images/star.png';
import style from './admin.module.scss';
import timerImage from 'assets/images/timer.png';


const QuestionsDetails = ({IO, IOConnected, onNavigate,
  options, refresh, currentQuestions}) => {

  const idRef = useRef(null);

  const [answers, setAnswers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalReturn, setShowModalReturn] = useState(false);
  const [question, setQuestion] = useState(null);
  const [nextIndex, setNextIndex] = useState(0);
  const [fetched, setFetched] = useState(false);
  const [innerRefresh, setInnerRefresh] = useState(0);
  const [info, setInfo] = useState({});
  const [showResetModal, setShowResetModal] = useState(false);
  const [timer, setTimer] = useState(99999);

  const getTime = useCallback((seconds) => {
    const min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;
    if (sec < 0) {
      sec = 0;
    }
    return `${`0${min}`.slice(-2)}:${`0${sec}`.slice(-2)}`;
  }, []);

  const validateReset = useCallback(() => {
    if (IO && IOConnected) {
      IO.emit('reset-session');
      // onNavigate('admin-welcome');
    }
  }, [IO, IOConnected]);

  const hasNextQuestion = useCallback(() => {
    const index = currentQuestions.findIndex(
      (q) => question.id === q.id) + 1;
    return index;
  }, [currentQuestions, question?.id]);

  const validateReturn = useCallback(() => {
    if (IO && IOConnected) {
        IO.emit('navigate-to', 'participant-waiting-for-next-question');
        onNavigate('admin-patient-case', {time: timer});
        setShowModalReturn(false);
      }
      
  }, [IO, IOConnected, onNavigate]);

  const validate = useCallback(() => {
    if (IO && IOConnected) {
      const index = hasNextQuestion();
      if (index < currentQuestions.length) {
        setNextIndex(index + 1);
        IO.emit('navigate-with-params-to', {
          navigation: 'participant-quizz',
          params: {id: currentQuestions[index].id}
        });
        setQuestion({...currentQuestions[index]});
        setFetched(false);
        setTimer(99999);
      }
      setShowModal(false);
    }
  }, [IO, IOConnected, currentQuestions, hasNextQuestion]);

  useEffect(() => {
    if (question === null) {
      setQuestion(options.question);
    }
  }, [question, options?.question]);

  useEffect(() => {
    if (timer === 99999 && question?.time) { 
      if (options.time !== undefined) {
        setTimer(options.time);
      } else {
        setTimer(question.time);
      }
    }
  }, [options?.time, question?.time, timer]);

  useEffect(() => {
    let timerId;
    if (timer >= 0 && question?.time) {
      timerId = setTimeout(() => {
        const time = timer - 1;
        setTimer(time >= 0 ? time : 0);
        if (time <= 0) {
          //onNavigate('participant-waiting-for-after-timeout');
        }
        clearTimeout(timerId);
      }, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [onNavigate, question?.time, timer]);
  
  useEffect(() => {
    return () => {
      setAnswers([]);
      setShowModal(false);
      setShowModalReturn(false);
      setQuestion(null);
      setNextIndex(0);
      setFetched(false);
      setInnerRefresh(0);
      setInfo({});
      setShowResetModal(false);
      setTimer(99999);

    }
  }, []);

  const previous = usePrevious({refresh, innerRefresh});

  useEffect(() => {
    if (IO && IOConnected) {
      const handleNewAnswer = () => {
        console.log('new-answer');
        clearTimeout(idRef.current);
        idRef.current = setTimeout(() => {
          setInnerRefresh(prevInnerRefresh => prevInnerRefresh + 1);
        }, 500);
      };

      IO.on('new-answer', handleNewAnswer);

      return () => {
        clearTimeout(idRef.current);
        IO.off('new-answer', handleNewAnswer);
      };
    }
  }, [IO, IOConnected]);

  useEffect(() => {
    if (IO && IOConnected && question?.id) {
      const handleQuestion = (q) => {
        setInfo(q.info);
        setAnswers(q.answers);
        setNextIndex(hasNextQuestion());
        console.info('Question fetched');
      };

      if (!fetched || +refresh !== +previous.refresh || +innerRefresh !== +previous.innerRefresh) {
        console.info('Emit Question');

        const emitQuestion = () => {
          console.info('Emit Question call');
          IO.emit('question', question.id);
        };

        IO.on('question', handleQuestion);
        emitQuestion();
        setFetched(true);

        return () => {
          IO.off('question', handleQuestion);
        };
      }
    }
  }, [IO, IOConnected, previous, question?.id, hasNextQuestion, fetched, refresh, innerRefresh /*important*/]);

  return (
    <>
      {
        showResetModal && <>
          <Modal
            onValidate={validateReset}
            onCancel={()=>{
              setShowResetModal(false);
            }}
          />
        </>
      }
      {
        showModal && <>
          <Modal
            onValidate={validate}
            onCancel={()=>{
              setShowModal(false);
            }}
          />
        </>
      }
      {
        showModalReturn && <>
          <Modal
            onValidate={validateReturn}
            onCancel={()=>{
              setShowModalReturn(false);
            }}
          />
        </>
      }
      <Row className={style.questionDetails}>
        <Col style={{
              backgroundImage:`url(${backgroundImage})`,
          }}>
          <Row className="header">
            <Row>
              <Col>
                <Button disabled={false} onClick={()=>{
                  onNavigate('admin-stats', {time: timer});
                }}><span>Statistiques</span></Button>
              </Col>
              <Col>
                <Button disabled={false} onClick={()=>{
                  const opts = {...options};
                  opts.question = question;
                  onNavigate('admin-ranking', {
                    origin: 'admin-question-details',
                    ...opts,
                  });
                }}><span>Classement</span></Button>
              </Col>
              <Col>
                <Button onClick={() => {
                    setShowResetModal(true);
                  }}><span>Reset</span></Button>
              </Col>
            </Row>
            <Row>
            <Col>
            <span style={{
              backgroundImage:`url(${timerImage})`
            }}>{getTime(timer)}</span></Col>
            </Row>
            {/*<Row />*/}
          </Row>
          <Row className="previous">
            <Col>
              <Button onClick={() => {
                onNavigate('admin-questions', {selected: options.case,
                  patientCaseNumber:options.patientCaseNumber});
              }}>
                <FontAwesomeIcon className="pt-1 mb-1" icon={faArrowLeftLong} />
              </Button>
            </Col>
            <Col>Question {nextIndex/*options.questionNumber*/}</Col>
          </Row>
          <Row className="top-body">
            <Col>
              <div>
                <span>{info.a}</span>
                <span>sur</span>
                <span>{info.b}</span>
              </div>
            </Col>
            <Col className="ques">
              <div>
                <span>{question?.name}</span>
              </div>
            </Col>
          </Row>
          <Row className="body">
            <Col>
              {answers?.length ? answers.map((answer, index) =>
                <Row key={index}>
                  <Col>
                    <div>
                      <span>{answer.nbr}</span>
                    </div>
                  </Col>
                  <Col style={{
                      position: 'relative'
                    }}>
                    <div className="ans">
                      <span>{answer.name}</span>
                    </div>
                    { answer?.points > 0 && question?.gameMode === 'pistolero' &&
                      <div className="pistolero-badge" style={{
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        width: '20px',
                        height: '20px',
                        position: 'absolute',
                        right: '20px',
                        top: '10px',
                        backgroundImage: `url(${starImage})`
                      }} />
                    }
                    { answer?.points > 0 && question.gameMode !== 'pistolero' &&
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'absolute',
                      top: '10px',
                      right: '20px',
                      borderRadius:'50%',
                      width:'10px',
                      height: '10px',
                      color: '#4B80AC',
                      border: '1px solid rgba(255, 255, 255, 0.8)',
                      backgroundColor: 'white',
                      fontWeight: 'bold',
                      fontSize:'12px',
                    }}>
                      <span />
                    </div>
                    }
                  </Col>
                </Row>
              ) : ''}
            </Col>
          </Row>
          <Row className="footer">
            <Col>
              {
                nextIndex >= currentQuestions.length ?
                <Button 
                  onClick={() => {
                    setShowModalReturn(true);
                  }}>Retourner aux cas patients</Button> :
                <Button 
                  disabled={nextIndex >= currentQuestions.length}
                  onClick={() => {
                  setShowModal(true);
                }}>Question suivante</Button>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </>
    );
}

export default QuestionsDetails;
