import { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'reactstrap';
import { getBadges } from '../global/Util';
import style from './participant.module.scss' ;
import backgroundImage from 'assets/images/background.png';

const ParticipantCities = (props) => {
  const { IO, IOConnected, onNavigate, refresh } = props;
  const [ cities, setCities ] = useState(null);
  const [selectedCities, setSelectedCities] = useState([]);

  useEffect(()=>{
    return ()=>{
      setCities(null);
    }
  }, []);

  const renderCols = useCallback((index) => {
    const citiesList = [];
    Array(3).fill(0).forEach((_, cell) => {
      const cityIndex = (index * 3) + cell;
      const city = cities[cityIndex];
      if (city) {
        const badge = getBadges(city.id);
        citiesList.push(
          <Col
            className={`city ${city.registered ? 'disabled' : ''}`}
            key={`${index} ${cell}`}

            // Here, do nothing if city is already selected
            onClick={()=>{
              if (!selectedCities.includes(city.id)) {
                IO.emit('selected-city', city.id);
                onNavigate('participant-city', {city});
              }
            }}>
            <i className="badge" style={{
              opacity: selectedCities.includes(city.id) ? 0.5 : 1,
              backgroundImage: badge ? `url(${badge.data})` : 'unset',
            }}/>
          </Col>
        );
      } else {
        citiesList.push(<Col key={`empty-${cityIndex}`}/>);
      }
    });
    return (
      <>
        {citiesList}
      </>
    );
  }, [cities, onNavigate, selectedCities]);

  const renderRows = useCallback(() => {
    const rows = [];
    Array(Math.ceil(cities.length / 3)).fill(0).forEach((_, index) =>
      rows.push(
        <Row key={index}>
          {
            renderCols(index)
          }
        </Row>
      )
    )
    return rows;
  }, [cities?.length, renderCols]);

  useEffect(() => {
    if (IO && IOConnected) {
      const handleCities = (result) => {
        setCities(result);
      };

      const handleSelectedCity = (id) => {
        // Do something with the selected city identifier
      };

      const handleSelectedCities = (selectedCities) => {
        setSelectedCities(JSON.parse(selectedCities));
      };

      IO.emit('cities');
      IO.on('cities', handleCities);
      IO.on('selected-city', handleSelectedCity);
      IO.on('selectedCities', handleSelectedCities);

      return () => {
        IO.off('cities', handleCities);
        IO.off('selected-city', handleSelectedCity);
        IO.off('selectedCities', handleSelectedCities);
      };
    }
  }, [IO, IOConnected, refresh /*important*/]);

  return (
  <>
    <Row className={style.cities}>
      <Col style={{
          backgroundImage:`url(${backgroundImage})`,
        }}>
        <Row className="header">
          <h1>Sélectionnez votre ville</h1>
        </Row>
        <Row className="body">
          <Col>
            { cities ?
              renderRows()
              : ''
            }
          </Col>
        </Row>
        {/*<Row className="footer">
          <Col>footer</Col>
        </Row>
        */}
      </Col>
    </Row>
  </>
  );
}

export default ParticipantCities;
